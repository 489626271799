<template>
    <div class="mine">mine</div>
</template>

<script>
// mine
export default {
    name: "mine",
    data() {
        return {}
    },
    mounted() {
    },
    methods: {}
}
</script>

<style scoped lang="less">
.mine {
}
</style>
